import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhoneVolume,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import { axiosbaseurl } from "../../config";
import { customToastOptions } from "../../Styles/popup_style";
import CustomLoader from "../CustomLoader";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    location: "",
    purpose: "",
    message: ""
  });
  const [loading, setLoading] = useState("");
  const [errors, setErrors] = useState({});
  const [contactDetails, setContactDetails] = useState({});
  const [headingData, setHeadingData] = useState({});
  const [social_links, setSocialLinks] = useState([]);
  const [zonalOffices, setZonalOffices] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    axiosbaseurl
      .get("/contactUsData")
      .then((res) => {
        setContactDetails(res.data.data);
        setHeadingData(res.data?.titles[0])
        getOffices();
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));

    return () => {
      setContactDetails({})
      controller.abort();
    };
  }, []);

  async function getSocialLinks() {
    try {
      const { data } = await axiosbaseurl.get(`/mediaLinks`);

      if (data.success == true) {
        setSocialLinks(data.data.links);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getOffices() {
    try {
      const { data } = await axiosbaseurl.get(`/offices`);

      if (data.success == true) {
        setZonalOffices(data.data);
        getSocialLinks();
      }
      // console.log(data)
    } catch (error) {
      console.log(error);
    }
  }

  // validations for first form
  const handleValidation = () => {
    const newErrors = {};

    if (!formData.fname) {
      newErrors.fname = "First name is required!";
    } else if (/\d/.test(formData.fname)) {
      newErrors.fname = "Digits not allowed in First Name";
    }

    if (!formData.lname) {
      newErrors.lname = "Last name is required";
    } else if (/\d/.test(formData.lname)) {
      newErrors.lname = "Digits not allowed in Last Name";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
    ) {
      newErrors.email = "Invalid email format";
    }

    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    }
    else if (formData.phone.length < 10) {
      newErrors.phone = "Invalid phone number";
    }

    if (!formData.location) {
      newErrors.location = "Zonal office is required";
    }

    if (!formData.purpose) {
      newErrors.purpose = "Connect purpose is required";
    }

    if (!formData.message) {
      newErrors.message = "Feedback is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // ===========================

  const handleContactForm = async (e) => {
    e.preventDefault();

    if (handleValidation()) {
      setLoading(true);
      try {
        const form_data = { ...formData, phone: formData.phone, type: formData?.purpose }
        const response = await axiosbaseurl.post("/enquiryData", form_data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log(response.data)
        setLoading(false);

        if (response.data.success == true) {
          toast.success("Inquiry Sent Successfully !!");
          setFormData({
            fname: "",
            lname: "",
            email: "",
            phone: "",
            location: "",
            purpose: "",
            message: "",
          });
        }
      } catch (error) {
        console.log("Error : ", error);
        toast.error("Something went wrong!");
        setLoading(false);
      }
    }
  };

  function collectFormData(e) {

    let value = e.target.value;
    // if (e.target.name === "phone") {
    //   if (value.startsWith('+91 ')) {
    //     value = value
    //   } else {
    //     value = ('+91 ' + value.replace(/^(\+91\s*)/, ''));
    //   }
    // }

    setFormData((prev) => ({ ...prev, [e.target.name]: value }));
  }

  return (
    <div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={customToastOptions}
      />

      <Helmet>
        <meta name="description" content="Propusers.com welcomes inquiries through their contact form and is located at H.N.-196 Ground Floor, Sector 63, Noida, Uttar Pradesh, PIN-201301" />
      </Helmet>
      {loading && <CustomLoader />}

      <div className="pt-90">
        <div
          className="bg-contact-img pb-5"
          style={{
            backgroundImage: `linear-gradient(#111111b0, #111111b0),url(${contactDetails?.image_url})`,
          }}
        >
          <div className="pb-3 px-md-5">
            <div className="container-box py-5 px-md-5">
              <div className="bd-contact-head d-flex justify-content-center">
                <div>
                  <h2 className="text-white mb-0">
                    <span className="text-yellow fw-normal">
                      {headingData?.title?.split(' ')[0]} </span>
                    {headingData?.title?.split(' ')?.slice(1)}
                  </h2>
                  <div className="yellow-line"></div>
                </div>
              </div>
              <div className="my-4 pb-1 text-center">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <p className="text-white bd-text-resp paragraph">
                      {headingData?.subtitle}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center w-100">
                <div className="col-md-12 col-xxl-11">
                  <div className="row justify-content-center contact-container">
                    <div className="col-md-7 position-relative ">
                      <div className="main-form-contact mb-40 bg-white w-100 h-100">
                        <form onSubmit={handleContactForm}>
                          <div className="all-tag-contact">
                            <div className="display-flex">
                              <div className="col-contact-6 bd-contact-page">
                                <label className=" ">First name <span className='text-danger'>*</span></label>
                                <input
                                  type="text"
                                  className="form-contact  "
                                  name="fname"
                                  value={formData.fname}
                                  onChange={collectFormData}
                                  placeholder="Enter First name"
                                  onKeyPress={(event) => {
                                    if (/[0-9]/.test(event.key))
                                      event.preventDefault();
                                  }}
                                />
                                <span className="text-danger badge">
                                  {errors?.fname}
                                </span>
                              </div>
                              <div className="col-contact-6 bd-contact-page">
                                <label className=" ">Last name <span className='text-danger'>*</span></label>
                                <input
                                  type="text"
                                  className="form-control1  "
                                  placeholder="Enter Last name"
                                  name="lname"
                                  value={formData.lname}
                                  onChange={collectFormData}
                                  onKeyPress={(event) => {
                                    if (/[0-9]/.test(event.key))
                                      event.preventDefault();
                                  }}
                                />
                                <span className="text-danger badge">
                                  {errors?.lname}
                                </span>
                              </div>
                            </div>
                            <div className="display-flex">
                              <div className="col-contact-6 bd-contact-page">
                                <label className=" ">Email <span className='text-danger'>*</span></label>
                                <input
                                  type="email"
                                  className="form-control1  "
                                  placeholder="Enter Email"
                                  name="email"
                                  value={formData.email}
                                  onChange={collectFormData}
                                />
                                <span className="text-danger badge">
                                  {errors?.email}
                                </span>
                              </div>
                              <div className="col-contact-6 bd-contact-page">
                                <label htmlFor="phone" className=" ">
                                  Phone No.
                                  <span className='text-danger'>*</span></label>
                                <br />
                                <div className="py-0 d-flex form-control1 align-items-center position-relative">
                                  <div className='  contact_phone'> +91 </div>
                                  <input
                                  type="text"
                                  className="form-control1"
                                  style={{ paddingLeft: "32px" }}
                                  placeholder="Enter Phone No."
                                  name="phone"
                                  maxLength={11}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                                  }}
                                  value={
                                    formData.phone && formData.phone.length > 5
                                      ? `${formData.phone.slice(0, 5)}-${formData.phone.slice(5)}`
                                      : formData.phone
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value.replace(/[^0-9]/g, ''); // Remove any non-digit character
                                    collectFormData({ target: { name: 'phone', value } }); // Pass the cleaned phone number (without hyphen)
                                  }}
                                  id="phone"
                                />
                                </div>
                                <span className="text-danger badge">
                                  {errors?.phone}
                                </span>
                              </div>
                            </div>
                            <div className="display-flex pt-10 pb-10 flex-column">
                              <div className="col-contact-6 bd-contact-page w-100">
                                <div className="position-relative">
                                  <select
                                    className="bd-select-option ps-3"
                                    name="location"
                                    value={formData.location}
                                    onChange={collectFormData}
                                  >
                                    <option value="">Zonal Offices</option>
                                    {zonalOffices?.map((office) => {
                                      return (
                                        <option
                                          key={office.id}
                                          value={office.company + " - " + office.city_name}
                                        >
                                          {office.company +
                                            " - " +
                                            office.city_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <i className="fa fa-chevron-down text-yellow pass-eye"></i>
                                </div>
                                <span className="text-danger badge">
                                  {errors?.location}
                                </span>
                              </div>
                              <div className="col-contact-6 bd-contact-page w-100">
                                <div className=" position-relative">
                                  <select
                                    className="bd-select-option ps-3"
                                    name="purpose"
                                    id="purpose"
                                    value={formData.purpose}
                                    onChange={collectFormData}
                                  >
                                    <option value="">Purpose to Connect</option>
                                    <option value="buy">For Buy</option>
                                    <option value="sell">For Sell</option>
                                    <option value="rent">For Rent</option>
                                    <option value="general">
                                      For Propreneur
                                    </option>
                                  </select>
                                  <label htmlFor="purpose">
                                    <i className="fa fa-chevron-down text-yellow pass-eye"></i>
                                  </label>
                                </div>
                                <span className="text-danger badge">
                                  {errors?.purpose}
                                </span>
                              </div>
                            </div>
                            <div className="col-contact-12 bd-pc-view ">
                              <label className="bd-inside-label  ">
                                Description <span className='text-danger'>*</span></label>
                              <br />
                              <textarea
                                placeholder="Write Your Message ..."
                                className="bd-contact-textarea w-100  "
                                name="message"
                                value={formData.message}
                                onChange={collectFormData}
                              ></textarea>
                              <span className="text-danger badge">
                                {errors?.message}
                              </span>
                            </div>
                          </div>
                          <div className="text-center w-100">
                            <button className="bd-contact-submit  ">
                              {loading ? "Submitting.." : "Submit"}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-5 position-relative pe-0 ps-4">
                      <div className="bd-contact-details contact-yellow-box w-100" >
                        <div className="bg-contact-detail-img w-100">
                          <div className="bd-detail-head mb-4 pb-1">
                            <div>
                              <h4>Contact Information</h4>
                            </div>
                            <div>
                              <p className="paragraph2   w-75">
                                {contactDetails?.contact_info}
                              </p>
                            </div>
                          </div>
                          <div className="bd-contact-address-detail">
                            <div className="d-flex mb-3">
                              <div className="pr-20">
                                <FontAwesomeIcon
                                  icon={faLocationDot}
                                  size="lg"
                                />
                              </div>
                              <div>
                                <h5>Our Corporate Address</h5>
                                <a href={contactDetails?.link} target="_blank"
                                  rel="noreferrer" className="paragraph2 text-decoration-none mb-0  ">
                                  {contactDetails?.contact_addresses}
                                </a>
                              </div>
                            </div>
                            <div className="d-flex mb-3">
                              <div className="pr-20">
                                <FontAwesomeIcon icon={faEnvelope} size="lg" />
                              </div>
                              <div>
                                <h5>E-mail</h5>
                                {contactDetails?.contact_mails
                                  ?.split(",")
                                  .map((number, index) => (
                                    <div key={index}>
                                      <a
                                        href={`mailto:${number}`}
                                        className="mb-0 paragraph2 text-decoration-none  "
                                      >
                                        {number}
                                      </a>
                                    </div>
                                  ))}
                                {/* <a
                                  href={`mailto:${contactDetails?.contact_mails}`}
                                  className="paragraph2 text-decoration-none  "
                                >
                                  {contactDetails?.contact_mails}
                                </a> */}
                              </div>
                            </div>
                            <div className="d-flex mb-4">
                              <div className="pr-20">
                                <FontAwesomeIcon
                                  icon={faPhoneVolume}
                                  size="lg"
                                />
                              </div>
                              <div>
                                <h5>Call</h5>
                                {contactDetails?.contact_numbers
                                  ?.split(",")
                                  .map((number, index) => (
                                    <div key={index}>
                                      <a
                                        href={`tel:${number}`}
                                        className="mb-0 paragraph2 text-decoration-none  "
                                      >
                                        {number}
                                      </a>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className="bd-icons-contact ms-md-4 mt-5">
                            <a
                              href={social_links[0]?.url}
                              target="_blank"
                              rel="noreferrer"
                              className="mx-2 px-1"
                            >
                              <FontAwesomeIcon
                                icon={faFacebookF}
                                className="fs-4"
                              />
                            </a>
                            <a
                              href={social_links[4]?.url}
                              target="_blank"
                              rel="noreferrer"
                              className="mx-2 px-1"
                            >
                              <FontAwesomeIcon
                                icon={faYoutube}
                                className="fs-4"
                              />
                            </a>
                            <a
                              href={social_links[1]?.url}
                              target="_blank"
                              rel="noreferrer"
                              className="mx-2 px-1"
                            >
                              <i className="bi bi-twitter-x fs-5"></i>
                            </a>
                            <a
                              href={social_links[2]?.url}
                              target="_blank"
                              rel="noreferrer"
                              className="mx-2 px-1"
                            >
                              <FontAwesomeIcon
                                icon={faInstagram}
                                className="fs-4"
                              />
                            </a>
                            <a
                              href={social_links[3]?.url}
                              target="_blank"
                              rel="noreferrer"
                              className="mx-2 px-1"
                            >
                              <FontAwesomeIcon
                                icon={faLinkedinIn}
                                className="fs-4"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
