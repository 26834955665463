import React from 'react'
import structure1 from "../../../assets/BuyDetailPage/structure1.png"
import structure2 from "../../../assets/BuyDetailPage/structure2.png"
import structure3 from "../../../assets/BuyDetailPage/structure3.png"

const FloorPlans = ({currentShowCase}) => {
    
    return (
        <div className="row">
            {
                currentShowCase && currentShowCase?.map(image => {
                    return (
                        <div className="col-md-4 mb-md-0 mb-4">
                            <div className="photo-card">
                                <img src={image?.upload_name} alt="" />
                                <div><p>{image?.tag}</p></div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default FloorPlans