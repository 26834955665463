import React, { useEffect, useState } from 'react'
import CostingAccordian from './CostingAccordian'
import './Costing.css'
import { useParams } from 'react-router-dom'
import { axiosbaseurl } from '../../../config'
import CustomLoader from '../../CustomLoader'

const BuyCosting = () => {

    const { ID } = useParams();
    const [Loading , setLoading] = useState(false);
    const [Property , setCurrentProperty] = useState({})

    useEffect(() => {
    
            const controller = new AbortController();
            async function getPropExpData() {
                try {
                    setLoading(true);
                    const sendData = {
                        property_id : ID,
                        user_id: 0
                    }
                    const { data } = await axiosbaseurl.post(`/propertiesData`, sendData);
    
                    if (data.success == true) {
                        const curr_pro = data.data[0]
                        setCurrentProperty(curr_pro)
                        // Storing Slider Images
                        setLoading(false);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
    
            getPropExpData();
    
            return () => {
                controller.abort();
            };
    }, []);

    const accordianData = [
        {
            id: 1,
            label: "Basic Selling Price",
            amount: Property?.pricedetails?.["Basic Selling Price"]?.total_amount,
            Charges : Property?.pricedetails?.["Basic Selling Price"]?.prices
        },
        {
            id: 2,
            label: "Other Charges",
            amount: Property?.pricedetails?.["Other Charges"]?.total_amount,
            Charges : Property?.pricedetails?.["Other Charges"]?.prices
        },
        {
            id: 3,
            label: "Government Charges",
            amount: Property?.pricedetails?.["Government Charges"]?.total_amount,
            Charges : Property?.pricedetails?.["Government Charges"]?.prices
        },
        {
            id: 4,
            label: "Authority Charges",
            amount: Property?.pricedetails?.["Authority Charges"]?.total_amount,
            Charges : Property?.pricedetails?.["Authority Charges"]?.prices	
        }
    ]

    return (
        <section className='pt-90 px-md-5 px-4'>
            {
                Loading && <CustomLoader/>
            }
            <div className="pt-4 pb-5">
                <div className="bd-underscore-overview">
                    <h3 className="bd-contact-header font-saira text-uppercase">Costing</h3>
                </div>
            </div>
            <div className='mb-5'>
                <div className="row">
                    <div className="col-md-9">
                        <div className='p-md-4 p-3' style={{ boxShadow: "0px 0px 6px #00000029", borderRadius: '10px' }}>
                            <div className="main-row">
                                <div className="bd-costing-section1 main-row justify-content-between align-items-center">
                                    <div className=" ">
                                        <p className="mb-0 bd-cost-text pl-10 font-saira">Actual Price</p>
                                    </div>
                                    <div className=" ">
                                        <p className="mb-0 bd-main-cost font-saira">&#8377; {Property.offer_price?.toLocaleString()}</p>
                                    </div>
                                </div>
                                <div className="bd-costing-section2 d-flex align-items-center justify-content-center">
                                    <div className="textCenter">
                                        <a to={`/property-buy-costing-details`} style={{ textDecoration: "none" }}>
                                            <p className="mb-0 " style={{ fontSize: "33px", color: "white" }}>+</p>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4'>
                                {
                                    accordianData.map((accordian_data) => {
                                        return <CostingAccordian key={accordian_data.id} Price_Data={Property?.pricedetails} accordian_data={accordian_data} />
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BuyCosting