import React, { useState } from 'react'

const CostingAccordian = ({accordian_data}) => {

    const [accordian, setAccordian] = useState(false);


    return (
        <div className='mb-4'>
            <div className='cost-accordian'>
                <div className={`cost-accordian-header ${accordian && 'border-bottom border-secondary'} `}>
                    <div className='d-flex justify-content-between align-items-center px-md-4 px-3 py-md-2 h-100' style={{ flex: 1 }}>
                        <div className='fw-bold text-dark-blue font-saira'>{accordian_data.label}</div>
                        <div className='fw-bold font-saira fs-5' style={{color: '#353939'}}>₹ {
                        accordian_data?.amount?.toLocaleString()}</div>
                    </div>
                    <div className='h-100'>
                        <button className='py-1' onClick={() => {
                            setAccordian(prev => !prev)
                        }}>
                            {accordian ? '-' : '+'}
                        </button>
                    </div>
                </div>
                {
                    accordian &&
                    <div className='table-responsive'>
                        <table className='table accordian-table text-center mb-0'>
                            <thead className='border-bottom border-secondary'>
                                <tr>
                                    <th>Type</th>
                                    <th>Rate / % / Fixed</th>
                                    <th>Calculate</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accordian_data?.Charges?.map((item) => (
                                    <tr>
                                        <td>{item?.type}</td>
                                        <td>{item?.rate}</td>
                                        <td>{item?.calculate}</td>
                                        <td>₹ {item.amount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    )
}

export default CostingAccordian